<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <h3>請輸入當日{{ checkType === 'A' ? '上' : '下' }}班時間</h3>
    </div>
    <form class="row flex-row mx-3" @submit.prevent="saveData">
      <!-- date -->
      <div class="col-12 text-start input-group">
        <label for="date">日期</label>
        <a-date-picker
          class="col"
          type="date"
          @change="
            (date) => {
              form.date = date;
            }
          "
          :default-value="defaultdate"
        />
      </div>
      <div>
        <label for="address" class="">地點:{{ addr }}</label>
      </div>
      <!-- time -->
      <div class="col-12 text-start input-group">
        <label for="timeAM" class="" v-if="checkType === 'A'">上班時間</label>
        <label for="timeAM" class="" v-else>下班時間</label>
        <a-time-picker
          format="HH:mm"
          v-model="timeAM1"
          :open="open1"
          @openChange="handleOpenChange"
          @change="
            (time) => {
              timeAM1 = time;
            }
          "
          class="col"
          :placeholder="checkType === 'A' ? '上班時間' : '下班時間'"
        >
          <!-- fail to add slot -->
          <a-button v-slot="addon" :slot-scope="panel" size="small" type="primary" @click="handleClose">
            Ok {{ panel.prefixCls }}
          </a-button>
        </a-time-picker>
      </div>
      <!-- <div class="col-12 text-start input-group">
        <label for="timeAM" class="">下午</label>
        <a-time-picker
          format="HH:mm"
          v-model="timePM1"
          @change="
            (time) => {
              timePM1 = time;
            }
          "
          class="col"
          placeholder="起始時間"
        />
        <span class="d-flex align-items-center mx-2">~</span>
        <a-time-picker
          format="HH:mm"
          v-model="timePM2"
          @change="
            (time) => {
              timePM2 = time;
              timePM2 > timePM1 ? '' : $message.error('不得晚於起始時間');
            }
          "
          class="col"
          placeholder="結束時間"
        />
      </div>
      <div class="col-12 text-start input-group">
        <label for="timeAM" class="">夜間</label>
        <a-time-picker
          format="HH:mm"
          v-model="timeMd1"
          @change="
            (time) => {
              timeMd1 = time;
            }
          "
          class="col"
          placeholder="起始時間"
        />
        <span class="d-flex align-items-center mx-2">~</span>
        <a-time-picker
          format="HH:mm"
          v-model="timeMd2"
          @change="
            (time) => {
              timeMd2 = time;
              timeMd2 > timeMd1 ? '' : $message.error('不得晚於起始時間');
            }
          "
          class="col"
          placeholder="結束時間"
        />
      </div> -->
      <!-- address -->
      <!-- <div class="col-12 text-start input-group">
        <label for="address" class="">地點</label>
        <input
          type="text"
          aria-label="請輸入地點"
          placeholder="請輸入地點"
          class="col form-control"
          v-model="form.address"
          required
        />
      </div>
      <div
        class="
          col-12
          my-2
          d-flex
          justify-content-between
          align-items-center
          border-1 border-top
          pt-3
          mt-4
        "
      >
        <h5>時數</h5>
        <h5>
          <input type="number" step=0.1 class="form-control w-25" v-model="totalTime" />
          <span>小時</span>
        </h5>
      </div> -->
      <div class="col-12 my-2">
        <button type="submit" class="btn btn-primary my-1 w-100 py-2">儲存</button>
      </div>
      <div class="col-12">
        <router-link to="/chooseROC">
          <button type="button" class="btn btn-outline-primary w-100 py-2">取消</button>
        </router-link>
      </div>
    </form>
  </div>
  <footer></footer>
</template>
<script>
import { onMounted } from 'vue';
import { reactive, toRefs, ref } from '@vue/reactivity';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';

import moment from 'moment';
export default {
  name: 'ReportForm',
  setup() {
    const router = useRouter();
    const route = useRoute();
    console.log(route.params.typeof);
    const defaultdate = moment(); //當前日期
    const form = reactive({
      date: null,
      address: null,
      na1name: null,
      timeAM1: null, //單位: 毫秒
      timeAM2: null,
      timePM1: null,
      timePM2: null,
      timeMd1: null,
      timeMd2: null,
    });
    const total1 = ref(0);
    // const total2 = ref(0);
    // const total3 = ref(0);
    const totalTime = ref(0);
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    let { us1no } = toRefs(user.value);
    // let { us1no } = toRefs(user.value);
    const submit = ref(false);
    const open1 = ref(false);
    const open2 = ref(false);
    const addr = ref('');
    const checkType = ref('');
    const { date, timeAM1, timeAM2, timePM1, timePM2, timeMd1, timeMd2 } = toRefs(form);
    const readytoLogout = computed(() => store.getters.logoutState);
    function handleOpenChange(open) {
      open1.value = open;
    }
    function handleClose() {
      open2.value = false;
    }

    // na1name.value = route.params.no; //route 參數
    // watchEffect(() => {
    //   count();
    // });
    onMounted(() => {
      addr.value = route.params.no; // 正确设置addr的值
      checkType.value = route.params.typeof;
    });
    // 計算時差
    // function count() {
    //   if (timeAM1.value && timeAM2.value) {
    //     if (timeAM2.value > timeAM1.value) {
    //       total1.value = timeAM2.value - timeAM1.value;
    //     } else {
    //       total1.value = 0;
    //     }
    //   }
    //   if (timePM1.value && timePM2.value) {
    //     if (timePM2.value > timePM1.value) {
    //       total2.value = timePM2.value - timePM1.value;
    //     } else {
    //       total2.value = 0;
    //     }
    //   }
    //   if (timeMd1.value && timeMd2.value) {
    //     // 無處理跨夜
    //     if (timeMd2.value > timeMd1.value) {
    //       total3.value = timeMd2.value - timeMd1.value;
    //     } else {
    //       total3.value = 0;
    //     }
    //   }
    //   totalTime.value = ((total1.value + total2.value + total3.value) / 3600000).toFixed(1);
    // }
    // 格式化時間
    function handleTimeFormat(val) {
      return moment(val).format('HHmm'); //24 hr
    }
    async function saveData() {
      // 格式轉換
      let t1 = handleTimeFormat(timeAM1.value);
      // let t2 = handleTimeFormat(timeAM2.value);
      // let t3 = handleTimeFormat(timePM1.value);
      // let t4 = handleTimeFormat(timePM2.value);
      // let t5 = handleTimeFormat(timeMd1.value);
      // let t6 = handleTimeFormat(timeMd2.value);
      let d1 = moment(date.value).format('YYYY-MM-DD');
      // if (!total1.value) {
      //   t1 = '';
      //   t2 = '';
      // }
      // if (!total2.value) {
      //   t3 = '';
      //   t4 = '';
      // }
      // if (!total3.value) {
      //   t5 = '';
      //   t6 = '';
      // }
      if (!date.value) {
        d1 = defaultdate.format('YYYY-MM-DD');
      }
      if (timeAM1.value) {
        // const res = await fetch(
        //   `${process.env.VUE_APP_BASE_URL}type=ADDPS2&na2name=${addr}&pe1no=${us1no._object.us1no}`
        // );

        try {
          const response = await fetch(
            `${process.env.VUE_APP_BASE_URL}type=${
              checkType.value === 'A' ? 'ADDPS2' : checkType.value === 'B' ? 'ADDPS22' : ''
            }&na2name=${addr.value}&pe1no=${us1no._object.us1no}&ps1btime=${t1}`
          );
          const result = await response.json();
          if (result.success) {
            alert('✔ 儲存成功');
            router.push('/chooseROC');
          } else {
            alert(`⚠ ${result.error} ⚠`);
          }
        } catch (error) {
          console.log(error);
        }
        // const res = await fetch(
        //   `${process.env.VUE_APP_BASE_URL}type=ADDPS1&na1name=${na1name.value}&ps1range=上午&ps1btime=${t1}&ps1etime=${t2}&ps1btime2=${t3}&ps1etime2=${t4}&ps1btime3=${t5}&ps1etime3=${t6}&ps1hr=${totalTime.value}&us1no=${us1no.value}&ps1date=${d1}&ps1addr=${address.value}`
        // ).catch((err) => console.log(err));
        // const result = await res.json();
        // console.log(result);
        // if (result.success) {
        //   alert('✔ 儲存成功');
        //   submit.value = true; //stop onBeforeRouteLeave
        //   router.push('/chooseProject');
        // } else {
        //   alert(`⚠ ${result.error} ⚠`);
        // }
        console.log(`ps1btime:${t1},type2:${checkType.value},na2name:${addr.value},pe1no:${us1no._object.us1no}`);
      } else {
        alert('⚠ 時間不得為空');
      }
    }
    // onBeforeRouteLeave((to) => {
    //   // 儲存, 登出時不啟動
    //   if (!submit.value & !readytoLogout.value) {
    //     const answer = window.confirm('⚠ 所填資料離開將清除，確定離開?');
    //     // 取消留在原頁
    //     if (!answer) return false;
    //   }
    //   if (readytoLogout.value) {
    //     to.path = '/';
    //   }
    // });
    return {
      form,
      saveData,
      timeAM1,
      timeAM2,
      timePM1,
      timePM2,
      timeMd1,
      timeMd2,
      totalTime,
      defaultdate,
      open1,
      open2,
      handleOpenChange,
      handleClose,
      addr,
      checkType,
    };
  },
  // antd option 寫法(not work with setup)
  // methods: {
  //   success() {
  //     this.$message
  //       .loading("Action in progress..", 2)
  //       .then(() => this.$message.success("儲存成功!"));
  //   },
  //   error() {
  //     this.$message.error("不得晚於起始時間");
  //   },
  // },
};
</script>
<style scoped>
::placeholder {
  color: var(--input-line);
}
.input-group {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}
label {
  margin-right: 10px;
}
h5 {
  white-space: nowrap;
}
h5:nth-child(2),
h5 > input {
  font-weight: 600;
  color: var(--first-color);
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
h5 > input {
  font-size: 20px;
  text-align: center;
}
footer {
  /* for mobile keyboard */
  height: 50vh;
}
</style>
